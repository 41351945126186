import React from "react"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import "../styles/Contact.css"
import circlepng from "../images/circlepng.png"
import { Helmet } from "react-helmet"

export default function PrivacyPolicy() {
  return (
    <div>
      <Helmet>
        <title>
          Privacy Policy | ReAim - Reach your audience with push notifications
        </title>
      </Helmet>
      <Navigation />
      <div>
        <div className="contact-container">
          <div className="pricing-header rwd-pricing-header">
            <h1>Privacy Policy</h1>
          </div>
          <div className="box-info-container privacy-policy-grid">
            <div className="contact-box terms-box">
              <div className="pricing-header pricing-header-text">
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">1.</p>
                  <p className="privacy-policy-underline">Introduction</p>
                </div>
                <p>
                  Welcome to{" "}
                  <span className="privacy-policy-bold">Optinian Ltd..</span>
                </p>
                <p>
                  Optinian Ltd.(“us”, “we”, or “our”) operates{" "}
                  <a href="https://reaim.me">https://reaim.me</a>
                  (hereinafter referred to as{" "}
                  <span className="privacy-policy-bold">Service</span>).
                </p>
                <p>
                  Our Privacy Policy governs your visit to{" "}
                  <a href="https://reaim.me">https://reaim.me</a> , and explains
                  how we collect, safeguard and disclose information that
                  results from your use of our Service.
                </p>
                <p>
                  We use your data to provide and improve Service. By using
                  Service, you agree to the collection and use of information in
                  accordance with this policy. Unless otherwise defined in this
                  Privacy Policy, the terms used in this Privacy Policy have the
                  same meanings as in our Terms and Conditions.
                </p>
                <p>
                  Our Terms and Conditions{" "}
                  <span className="privacy-policy-bold">("Terms")</span> govern
                  all use of our Service and together with the Privacy Policy
                  constitutes your agreement with us{" "}
                  <span className="privacy-policy-bold">("agreement")</span>.
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">2.</p>
                  <p className="privacy-policy-underline">Definitions</p>
                </div>
                <p>
                  <span className="privacy-policy-bold">SERVICE</span> means the{" "}
                  <a href="https://reaim.me">https://reaim.me</a> website
                  operated by Optinian Ltd..
                </p>
                <p>
                  <span className="privacy-policy-bold">PERSONAL DATA</span>{" "}
                  means data about a living individual who can be identified
                  from those data (or from those and other information either in
                  our possession or likely to come into our possession).
                </p>
                <p>
                  <span className="privacy-policy-bold">USAGE DATA</span> is
                  data collected automatically either generated by the use of
                  Service or from Service infrastructure itself (for example,
                  the duration of a page visit).
                </p>
                <p>
                  <span className="privacy-policy-bold">COOKIES</span> are small
                  files stored on your device (computer or mobile device).
                </p>
                <p>
                  <span className="privacy-policy-bold">DATA CONTROLLER</span>{" "}
                  means a natural or legal person who (either alone or jointly
                  or in common with other persons) determines the purposes for
                  which and the manner in which any personal data are, or are to
                  be, processed. For the purpose of this Privacy Policy, we are
                  a Data Controller of your data.
                </p>
                <p>
                  <span className="privacy-policy-bold">
                    DATA PROCESSORS (OR SERVICE PROVIDERS)
                  </span>{" "}
                  means any natural or legal person who processes the data on
                  behalf of the Data Controller. We may use the services of
                  various Service Providers in order to process your data more
                  effectively.
                </p>
                <p>
                  <span className="privacy-policy-bold">DATA SUBJECTS</span> is
                  any living individual who is the subject of Personal Data.
                </p>
                <p>
                  <span className="privacy-policy-bold">THE USER</span> is the
                  individual using our Service. The User corresponds to the Data
                  Subject,who is the subject of Personal Data.
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">3.</p>
                  <p className="privacy-policy-underline">
                    Information Collection and Use
                  </p>
                </div>
                <p>
                  We collect several different types of information for various
                  purposes to provide and improve our Service to you.
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">4.</p>
                  <p className="privacy-policy-underline">
                    Types of Data Collected
                  </p>
                </div>
                <p>
                  <span className="privacy-policy-bold">Personal Data</span>
                  <br />
                  While using our Service, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you{" "}
                  <span className="privacy-policy-bold">
                    (“Personal Data”).
                  </span>{" "}
                  Personally identifiable information may include, but is not
                  limited to: <br /> <br />
                  (a) Email address <br /> <br />
                  (b) First name and the last name <br /> <br />
                  (c) Phone number <br /> <br />
                  (d) Address, State, Province, ZIP/Postal code, City <br />
                  <br />
                  (e) Cookies and Usage Data <br />
                </p>
                <p>
                  We may use your Personal Data to contact you with newsletters,
                  marketing or promotional materials and other information that
                  may be of interest to you. You may opt out of receiving any,
                  or all, of these communications from usby following the
                  unsubscribe link.
                </p>
                <p>
                  <span className="privacy-policy-bold">Usage Data</span>
                  <br />
                  We may also collect information that your browser sends
                  whenever you visit our Service or when you access Service by
                  or through a mobile device{" "}
                  <span className="privacy-policy-bold">("Usage Data").</span>
                </p>
                <p>
                  This Usage Data may include information such as your
                  computer's Internet Protocol address(e.g. IP address), browser
                  type, browser version, the pages of our Service that you
                  visit, the time and date of your visit, the time spent on
                  those pages, unique device identifiers andother diagnostic
                  data.
                </p>
                <p>
                  When you access Service with a mobile device, this Usage Data
                  may include information such as the type of mobile device you
                  use, your mobile device unique ID, the IP address of your
                  mobile device, your mobile operating system, the type of
                  mobile Internet browser youuse, unique device identifiers and
                  other diagnostic data.
                </p>
                <p>
                  <span className="privacy-policy-bold">Location Data</span>
                  <br />
                  We may use and store information about your location if you
                  give us permission to do so{" "}
                  <span className="privacy-policy-bold">
                    ("Location Data").
                  </span>{" "}
                  We use this data to provide features of our Service, to
                  improve and customize our Service.
                </p>
                <p>
                  You can enable or disable location services when you use our
                  Service at any time by way of your device settings.
                </p>
                <p>
                  <span className="privacy-policy-bold">
                    Tracking Cookies Data
                  </span>
                  <br />
                  We use cookies and similar tracking technologies to track the
                  activity on our Service and wehold certain information.
                </p>
                <p>
                  Cookies are files with a small amount of data which may
                  include an anonymous unique identifier. Cookies are sent to
                  your browser from a website and stored on your device. Other
                  tracking technologies are also used such as beacons, tags and
                  scripts to collect and track information and to improve and
                  analyze our Service.
                </p>
                <p>
                  You can instruct your browser to refuse all cookies or to
                  indicate when a cookie is being sent. However, if you do not
                  accept cookies, you may not be able to use some portions of
                  our Service.
                </p>
                <p>
                  Examples of Cookies we use: <br /> <br />
                  (a){" "}
                  <span className="privacy-policy-bold">
                    Session Cookies:
                  </span>{" "}
                  We use Session Cookies to operate our Service. <br /> <br />
                  (b){" "}
                  <span className="privacy-policy-bold">
                    Preference Cookies:
                  </span>{" "}
                  We use Preference Cookies to remember your preferences and
                  various settings.
                  <br /> <br />
                  (c){" "}
                  <span className="privacy-policy-bold">
                    Security Cookies:
                  </span>{" "}
                  We use Security Cookies for security purposes. <br /> <br />
                  (d){" "}
                  <span className="privacy-policy-bold">
                    Advertising Cookies:
                  </span>{" "}
                  Advertising Cookies are used to serve you with advertisements
                  that may be relevant to you and your interests. <br />
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">5.</p>
                  <p className="privacy-policy-underline">Use of Data</p>
                </div>
                <p>
                  Optinian Ltd.uses the collected data for various purposes:{" "}
                  <br /> <br />
                  (a) to provide and maintain our Service; <br /> <br />
                  (b) to notify you about changes to our Service; <br /> <br />
                  (c) to allow you to participate in interactive features of our
                  Service when you choose to do so;
                  <br /> <br />
                  (d) to provide customer support; <br />
                  <br />
                  (e) to gather analysis or valuable information so that we can
                  improve our Service; <br /> <br />
                  (f) to monitor the usage of our Service; <br /> <br />
                  (g) to detect, prevent and address technical issues; <br />
                  <br />
                  (h) to fulfill any other purpose for which you provide it;
                  <br />
                  <br />
                  (i) to carry out our obligations and enforce our rights
                  arising from any contracts entered into between you and us,
                  including for billing and collection; <br /> <br />
                  (j) to provide you with notices about your account and/or
                  subscription, including expiration and renewal notices,
                  email-instructions, etc.; <br /> <br />
                  (k) to provide you with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless you have opted not to
                  receive such information; <br /> <br />
                  (l) in any other way we may describe when you provide the
                  information; <br /> <br />
                  (m) for any other purpose with your consent. <br />
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">6.</p>
                  <p className="privacy-policy-underline">Retention of Data</p>
                </div>
                <p>
                  We will retain your Personal Data only for as long as is
                  necessary for the purposes set out in this Privacy Policy. We
                  will retain and use your Personal Data to the extent necessary
                  to comply with our legal obligations (for example, if we are
                  required to retain your data to comply with applicable laws),
                  resolve disputes, and enforce our legal agreements and
                  policies.
                </p>
                <p>
                  We will also retain Usage Data for internal analysis purposes.
                  Usage Data is generally retained for a shorter period, except
                  when this data is used to strengthen the security or to
                  improve the functionality of our Service, or we are legally
                  obligated to retain this data for longer time periods.
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">7.</p>
                  <p className="privacy-policy-underline">Transfer of Data</p>
                </div>
                <p>
                  Your information, including Personal Data, may be transferred
                  to – and maintained on – computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ from those of your
                  jurisdiction.
                </p>
                <p>
                  If you are located outside United States and choose to provide
                  information to us, please note that we transfer the data,
                  including Personal Data, to United States and process it
                  there.
                </p>
                <p>
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </p>
                <p>
                  Optinian Ltd. will take all the steps reasonably necessary to
                  ensure that your data is treated securely and in accordance
                  with this Privacy Policy and no transfer of your Personal Data
                  will take place to an organisation or a country unless there
                  are adequate controls in place including the security of your
                  data and other personal information
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">8.</p>
                  <p className="privacy-policy-underline">Disclosure of Data</p>
                </div>
                <p>
                  We may disclose personal information that we collect, or you
                  provide:
                </p>
                <span className="privacy-policy-bold">
                  (a) Disclosure for Law Enforcement.
                </span>{" "}
                <p className="privacy-policy-p-margin">
                  Under certain circumstances, we may be required to disclose
                  your Personal Data if required to do so by law or in response
                  to valid requests by public authorities.
                </p>
                <span className="privacy-policy-bold">
                  (b) Business Transaction.
                </span>{" "}
                <p className="privacy-policy-p-margin">
                  If we or our subsidiaries are involved in a merger,
                  acquisition or asset sale, your Personal Data may be
                  transferred.
                </p>
                <span className="privacy-policy-bold">
                  ( c ) Other cases. We may disclose your information also:
                </span>{" "}
                <p className="privacy-policy-p-margin">
                  (i) to our subsidiaries and affiliates;
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">9.</p>
                  <p className="privacy-policy-underline">Security of Data</p>
                </div>
                <p>
                  The security of your data is important to us but remember that
                  no method of transmission over the Internet or method of
                  electronic storage is 100% secure. While we strive to use
                  commercially acceptable means to protect your Personal Data,
                  we cannot guarantee its absolute security.
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">10.</p>
                  <p className="privacy-policy-underline">
                    Your Data Protection Rights Under General Data Protection
                    Regulation (GDPR)
                  </p>
                </div>
                <p>
                  If you are a resident of the European Union (EU) and European
                  Economic Area (EEA), you have certain data protection rights,
                  covered by GDPR. – See more at{" "}
                  <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
                    https://eur-lex.europa.eu/eli/reg/2016/679/oj
                  </a>
                </p>
                <p>
                  We aim to take reasonable steps to allow you to correct,
                  amend, delete, or limit the use of your Personal Data.
                </p>
                <p>
                  If you wish to be informed what Personal Data we hold about
                  you and if you want it to be removed from our systems, please
                  email us at support@reaim.me.
                </p>
                <p>
                  In certain circumstances, you have the following data
                  protection rights
                  <br /> <br />
                  (a) the right to access, update or to delete the information
                  we have on you; <br /> <br />
                  (b) the right of rectification. You have the right to have
                  your information rectified if that information is inaccurate
                  or incomplete; <br /> <br />
                  (c) the right to object. You have the right to object to our
                  processing of your Personal Data;
                  <br /> <br />
                  (d) the right of restriction. You have the right to request
                  that we restrict the processing of your personal information;{" "}
                  <br />
                  <br />
                  (e) the right to data portability. You have the right to be
                  provided with a copy of your Personal Data in a structured,
                  machine-readable and commonly used format;
                  <br /> <br />
                  (f) the right to withdraw consent. You also have the right to
                  withdraw your consent at any time where we rely on your
                  consent to process your personal information; <br />
                </p>
                <p>
                  Please note that we may ask you to verify your identity before
                  responding to such requests. Please note, we may not able to
                  provide Service without some necessary data.
                </p>
                <p>
                  You have the right to complain to a Data Protection Authority
                  about our collection and use of your Personal Data. For more
                  information, please contact your local data protection
                  authority in the European Economic Area (EEA).
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">11.</p>
                  <p className="privacy-policy-underline">
                    Your Data Protection Rights under the California Privacy
                    Protection Act (CalOPPA)
                  </p>
                </div>
                <p>
                  CalOPPA is the first state law in the nation to require
                  commercial websites and online services to post a privacy
                  policy. The law’s reach stretches well beyond California to
                  requirea person or company in the United States (and
                  conceivable the world) that operates websites collecting
                  personally identifiable information from California consumers
                  to post aconspicuous privacy policy on its website stating
                  exactly the information being collected and those individuals
                  with whom it is being shared, and to comply with this policy.
                  – See more at:{" "}
                  <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">
                    https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                  </a>
                </p>
                <p>
                  According to CalOPPA we agree to the following: <br /> <br />
                  (a) users can visit our site anonymously; <br /> <br />
                  (b) our Privacy Policy link includes the word “Privacy”, and
                  can easily be found on the pages pecified above on the home
                  page of our website; <br /> <br />
                  (c) users will be notified of any privacy policy changes on
                  our Privacy Policy Page; <br /> <br />
                  (d) users are able to change their personal information by
                  emailing us at support@reaim.me. <br />
                </p>
                <p>Our Policy on “Do Not Track” Signals:</p>
                <p>
                  We honor Do Not Track signals and do not track, plant cookies,
                  or use advertising when a Do Not Track browser mechanism is in
                  place. Do Not Track is a preference you can set in your web
                  browser to inform websites that you do not want to be tracked.
                </p>
                <p>
                  You can enable or disable Do Not Track by visiting the
                  Preferences or Settings page of your web browser.
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">12.</p>
                  <p className="privacy-policy-underline">
                    Your Data Protection Rights under the California Consumer
                    Privacy Act (CCPA)
                  </p>
                </div>
                <p>
                  If you are a California resident, you are entitled to learn
                  what data we collect about you, ask to delete your data and
                  not to sell (share) it. To exercise your data protection
                  rights, you can make certain requests and ask us:
                </p>
                <span className="privacy-policy-bold">
                  (a) What personal information we have about you.{" "}
                </span>{" "}
                <p className="privacy-policy-p-margin">
                  If you make this request, we will return to you:
                </p>
                <p>
                  (i) The categories of personal information we have collected
                  about you. <br /> <br />
                  (ii) The categories of sources from which we collect your
                  personal information. <br /> <br />
                  (iii) The business or commercial purpose for collecting or
                  selling your personal information. <br /> <br />
                  (iv) The categories of third parties with whom we share
                  personal information. <br /> <br />
                  (v) The specific pieces of personal information we have
                  collected about you. <br /> <br />
                  (vi) A list of categories of personal information that we have
                  sold, along with the category of any other company we sold it
                  to. If we have not sold your personal information, we will
                  inform you of that fact. <br /> <br />
                  (vii) A list of categories of personal information that we
                  have disclosed for a business purpose, along with the category
                  of any other company we shared it with. <br />
                </p>
                <p>
                  Please note, you are entitled to ask us to provide you with
                  this information up to two times in a rolling twelve-month
                  period. When you make this request, the information provided
                  may be limited to the personal information we collected about
                  you in the previous 12 months.
                </p>
                <span className="privacy-policy-bold">
                  (b) To delete your personal information.
                </span>{" "}
                <p className="privacy-policy-p-margin">
                  If you make this request, we will delete the personal
                  information we hold about you as of the date of your request
                  from our records and direct any service providers to do the
                  same. In some cases, deletion may be accomplished through
                  de-identification of the information. If you choose to delete
                  your personal information, you may not be able to use certain
                  functions that require your personal information to operate.
                </p>
                <span className="privacy-policy-bold">
                  ( c ) To stop selling your personal information.
                </span>
                <p className="privacy-policy-p-margin">
                  We don't sell or rent your personal information to any third
                  parties for any purpose. You are the only owner of your
                  Personal Data and can request disclosure or deletion at any
                  time.
                </p>
                <p>
                  Please note, if you ask us to delete or stop selling your
                  data, it may impact your experience with us, and you may not
                  be able to participate in certain programs or membership
                  services which require the usage of your personal information
                  to function.But in no circumstances, we will discriminate
                  against you for exercising your rights.
                </p>
                <p>
                  To exercise your California data protection rights described
                  above, please send your request(s) by one of the following
                  means:
                </p>
                <p>By email: support@reaim.me</p>
                <p>
                  Your data protection rights, described above, are covered by
                  the CCPA, short for the California Consumer Privacy Act. To
                  find out more, visit the official California Legislative
                  Information website. The CCPA took effect on 01/01/2020.{" "}
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">13.</p>
                  <p className="privacy-policy-underline">Service Providers</p>
                </div>
                <p>
                  We may employ third party companies and individuals to
                  facilitate our Service{" "}
                  <span className="privacy-policy-bold">
                    (“ServiceProviders”)
                  </span>
                  , provide Service on our behalf, perform Service-related
                  services or assist us in analysing how our Service is used.
                </p>
                <p>
                  These third parties have access to your Personal Data only to
                  perform these tasks on our behalf and are obligated not to
                  disclose or use it for any other purpose.
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">14.</p>
                  <p className="privacy-policy-underline">Analytics</p>
                </div>
                <p>
                  We may use third-party Service Providers to monitor and
                  analyze the use of our Service.
                </p>
                <span className="privacy-policy-bold">Google Analytics</span>
                <p className="privacy-policy-p-margin">
                  Google Analytics is a web analytics service offered by Google
                  that tracks and reports website traffic. Google uses the data
                  collected to track and monitor the use of our Service. This
                  data is shared with other Google services. Google may use the
                  collected data to contextualise and personalise the ads of its
                  own advertising network.
                </p>
                <p>
                  For more information on the privacy practices of Google,
                  please visit the Google Privacy Terms web page:{" "}
                  <a href="https://policies.google.com/privacy?hl=en">
                    https://policies.google.com/privacy?hl=en
                  </a>
                </p>
                <p>
                  We also encourage you to review the Google's policy for safe
                  guarding your data:{" "}
                  <a href="https://support.google.com/analytics/answer/6004245">
                    https://support.google.com/analytics/answer/6004245
                  </a>
                </p>
                <span className="privacy-policy-bold">
                  Cloudflare analytics
                </span>
                <p className="privacy-policy-p-margin">
                  Cloudflare analytics is a web analytics service operated by
                  Cloudflare Inc. Read the Privacy Policy here:{" "}
                  <a href="https://www.cloudflare.com/privacypolicy/">
                    https://www.cloudflare.com/privacypolicy/
                  </a>
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">15.</p>
                  <p className="privacy-policy-underline">CI/CD tools</p>
                </div>
                <p>
                  We may use third-party Service Providers to automate the
                  development process of our Service.
                </p>
                <span className="privacy-policy-bold">GitHub</span>
                <p className="privacy-policy-p-margin">
                  GitHub is provided by GitHub, Inc.
                </p>
                <p>
                  GitHub is a development platform to host and review code,
                  manage projects, and build software.
                </p>
                <p>
                  For more information on what data GitHub collects for what
                  purpose and how the protection of the data is ensured, please
                  visit GitHub Privacy Policy page:{" "}
                  <a href="https://help.github.com/en/articles/github-privacy-statement">
                    https://help.github.com/en/articles/github-privacy-statement
                  </a>
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">16.</p>
                  <p className="privacy-policy-underline">
                    Behavioral Remarketing
                  </p>
                </div>
                <p>
                  Optinian Ltd. uses remarketing services to advertise on third
                  party websites to you after you visited our Service. We and
                  our third-party vendors use cookies to inform, optimise and
                  serve ads based on your past visits to our Service.
                </p>
                <span className="privacy-policy-bold">
                  Google Ads (AdWords)
                </span>
                <p className="privacy-policy-p-margin">
                  Google Ads (AdWords) remarketing service is provided by Google
                  Inc.
                </p>
                <p>
                  You can opt-out of Google Analytics for Display Advertising
                  and customize the GoogleDisplay Network ads by visiting the
                  Google Ads Settings page:{" "}
                  <a href=" http://www.google.com/settings/ads">
                    http://www.google.com/settings/ads
                  </a>
                </p>
                <p>
                  Google also recommends installing the Google Analytics Opt-out
                  Browser Add-on –{" "}
                  <a href="https://tools.google.com/dlpage/gaoptout">
                    https://tools.google.com/dlpage/gaoptout
                  </a>{" "}
                  – for your web browser. Google Analytics Opt-out.
                </p>
                <p>
                  Browser Add-on provides visitors with the ability to prevent
                  their data from being collected and used by Google Analytics.
                </p>
                <p>
                  For more information on the privacy practices of Google,
                  please visit the Google PrivacyTerms web page:{" "}
                  <a href="https://policies.google.com/privacy?hl=en">
                    https://policies.google.com/privacy?hl=en
                  </a>
                </p>
                <span className="privacy-policy-bold">
                  Bing Ads Remarketing
                </span>
                <p className="privacy-policy-p-margin">
                  Bing Ads remarketing service is provided by Microsoft Inc.
                </p>
                <p>
                  You can opt-out of Bing Ads interest-based ads by following
                  their instructions:{" "}
                  <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
                    https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                  </a>
                </p>
                <p>
                  You can learn more about the privacy practices and policies of
                  Microsoft by visiting their Privacy Policy page:{" "}
                  <a href="https://privacy.microsoft.com/en-us/PrivacyStatement">
                    https://privacy.microsoft.com/en-us/PrivacyStatement
                  </a>
                </p>
                <span className="privacy-policy-bold">Twitter</span>
                <p className="privacy-policy-p-margin">
                  Twitter remarketing service is provided by Twitter Inc.
                </p>
                <p>
                  You can opt-out from Twitter's interest-based ads by following
                  their instructions:{" "}
                  <a href="https://support.twitter.com/articles/20170405">
                    https://support.twitter.com/articles/20170405
                  </a>
                </p>
                <p>
                  You can learn more about the privacy practices and policies of
                  Twitter by visiting their Privacy Policy page:{" "}
                  <a href="https://twitter.com/privacy">
                    https://twitter.com/privacy
                  </a>
                </p>
                <span className="privacy-policy-bold">Facebook</span>
                <p className="privacy-policy-p-margin">
                  Facebook remarketing service is provided by Facebook Inc.
                </p>
                <p>
                  You can learn more about interest-based advertising from
                  Facebook by visiting this page:{" "}
                  <a href="https://www.facebook.com/help/164968693837950">
                    https://www.facebook.com/help/164968693837950
                  </a>
                </p>
                <p>
                  To opt-out from Facebook's interest-based ads, follow these
                  instructions from Facebook:{" "}
                  <a href="https://www.facebook.com/help/568137493302217">
                    https://www.facebook.com/help/568137493302217
                  </a>
                </p>
                <p>
                  Facebook adheres to the Self-Regulatory Principles for Online
                  Behavioural Advertising established by the Digital Advertising
                  Alliance. You can also opt-out from Facebook and other
                  participating companies through the Digital Advertising
                  Alliance in the USA{" "}
                  <a href="http://www.aboutads.info/choices/ ">
                    http://www.aboutads.info/choices/{" "}
                  </a>
                  , the Digital Advertising Alliance of Canada in Canada{" "}
                  <a href="http://youradchoices.ca/">
                    http://youradchoices.ca/
                  </a>{" "}
                  or the European Interactive Digital Advertising Alliance in
                  Europe{" "}
                  <a href="http://www.youronlinechoices.eu/">
                    http://www.youronlinechoices.eu/
                  </a>{" "}
                  , or opt-out using your mobile device settings.
                </p>
                <p>
                  For more information on the privacy practices of Facebook,
                  please visit Facebook's Data Policy:{" "}
                  <a href="https://www.facebook.com/privacy/explanation">
                    https://www.facebook.com/privacy/explanation
                  </a>
                </p>
                <span className="privacy-policy-bold">Pinterest</span>
                <p className="privacy-policy-p-margin">
                  Pinterest remarketing service is provided by Pinterest Inc.
                </p>
                <p>
                  You can opt-out from Pinterest's interest-based ads by
                  enabling the “Do Not Track”functionality of your web browser
                  or by following Pinterest instructions:{" "}
                  <a href="http://help.pinterest.com/en/articles/personalization-and-data">
                    http://help.pinterest.com/en/articles/personalization-and-data
                  </a>
                </p>
                <p>
                  You can learn more about the privacy practices and policies of
                  Pinterest by visiting their Privacy Policy page:{" "}
                  <a href="https://about.pinterest.com/en/privacy-policy">
                    https://about.pinterest.com/en/privacy-policy
                  </a>
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">17.</p>
                  <p className="privacy-policy-underline">Payments</p>
                </div>
                <p>
                  We may provide paid products and/or services within Service.
                  In that case, we use third-party services for payment
                  processing (e.g. payment processors).
                </p>
                <p>
                  We will not store or collect your payment card details. That
                  information is provided directly to our third-party payment
                  processors whose use of your personal information is governed
                  by their Privacy Policy. These payment processors adhere to
                  the standards set by PCI-DSS as managed by the PCI Security
                  Standards Council, which is a joint effort of brands like
                  Visa, Mastercard, American Express and Discover. PCI-DSS
                  requirements help ensure the secure handling of payment
                  information.
                </p>
                <p>The payment processors we work with are:</p>
                <span className="privacy-policy-bold">
                  PayPal or Braintree:
                </span>
                <p className="privacy-policy-p-margin">
                  Their Privacy Policy can be viewed at{" "}
                  <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">
                    https://www.paypal.com/webapps/mpp/ua/privacy-full
                  </a>
                </p>
                <span className="privacy-policy-bold">Stripe</span>
                <p className="privacy-policy-p-margin">
                  Their Privacy Policy can be viewed at:{" "}
                  <a href="https://stripe.com/us/privacy">
                    https://stripe.com/us/privacy
                  </a>
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">18.</p>
                  <p className="privacy-policy-underline">
                    Links to Other Sites
                  </p>
                </div>
                <p>
                  Our Service may contain links to other sites that are not
                  operated by us. If you click a third party link, you will be
                  directed to that third party's site. We strongly advise you to
                  review the Privacy Policy of every site you visit.
                </p>
                <p>
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">19.</p>
                  <p className="privacy-policy-underline">Children's Privacy</p>
                </div>
                <p>
                  Our Services are not intended for use by children under the
                  age of 13{" "}
                  <span className="privacy-policy-bold">(“Children”)</span>{" "}
                </p>
                <p>
                  We do not knowingly collect personally identifiable
                  information from Children under 13. If you become aware that a
                  Child has provided us with Personal Data, please contact us.
                  If we become aware that we have collected Personal Data from
                  Children without verification of parental consent, we take
                  steps to remove that information from our servers.
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">20.</p>
                  <p className="privacy-policy-underline">
                    Changes to This Privacy Policy
                  </p>
                </div>
                <p>
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p>
                <p>
                  We will let you know via email and/or a prominent notice on
                  our Service, prior to the change becoming effective and update
                  “effective date” at the top of this Privacy Policy.
                </p>
                <p>
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </p>
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">21.</p>
                  <p className="privacy-policy-underline">Contact Us</p>
                </div>
                <p>
                  If you have any questions about this Privacy Policy, please
                  contact us:
                </p>
                <p>By email: support@reaim.me.</p>
              </div>
            </div>

            <div className="contact-circle">
              <img
                src={circlepng}
                className="contact-circle-img"
                alt="circle"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
